import React from 'react';
import {
  Col,
  Container,
  Row,
  Form,
  Input,
  FormGroup,
  Button
} from 'reactstrap';

const ContactForm = () => (
  <Row className="py-5">
    <Container>
      <Row>
        <Col xs={12}>
          <h1>Get In Touch</h1>
          <hr />
        </Col>
        <Col xs={12}>
          <Form data-netlify="true" name="contact" action="/success">
            <Input type="hidden" name="form-name" value="contact" />
            <FormGroup>
              <Input type="text" name="name" id="name" placeholder="Name" />
            </FormGroup>
            <FormGroup>
              <Input type="email" name="email" id="email" placeholder="Email" />
            </FormGroup>
            <FormGroup>
              <Input type="tel" name="telephone" id="telephone" placeholder="Phone/Whatsapp" />
            </FormGroup>
            <FormGroup>
              <Input type="textarea" name="text" id="message" placeholder="Your message" />
            </FormGroup>
            <Button block color="dark" className="rounded-0">Submit</Button>
          </Form>
        </Col>
      </Row>
    </Container>
  </Row>
);

export default ContactForm;
