import React from 'react';
import { graphql, StaticQuery } from 'gatsby';
import { Col } from 'reactstrap';

import BackgroundImage from 'gatsby-background-image';

const SecondRow = () => (
  <StaticQuery query={graphql`
      query {
        desktop: file(relativePath: { eq: "bg-amp.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 4160) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `}
    render={data => (
      <BackgroundImage Tag="section"
        className="row py-5 justify-content-center"
        fluid={data.desktop.childImageSharp.fluid}
        backgroundColor={`#dc947b`}
      >
        <Col xs={12} sm={10} md={9} lg={8} className="text-center bg-white py-4 my-5">
          <h2>For Audio Solutions</h2>
          <h1 className="massive brand-name mb-0">Audire</h1>
        </Col>
      </BackgroundImage>
    )
    }
  />
);

export default SecondRow;
