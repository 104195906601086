import React from "react";
import {
  Container,
  Row,
  Col,
} from 'reactstrap';
import SecondRow from '../components/second-row';
import ImageRow from '../components/image-row';
import ContactForm from '../components/contact-form';

import Layout from "../components/layout";
import SEO from "../components/seo";

const IndexPage = () => (
  <Layout>
    <SEO title="Audire" keywords={[`audire`, `audio`, `mixing`, `sound`, `events`]} />

    <Container fluid>
      <SecondRow />

      <Row className="bg-theme py-5">
        <Container>
          <Row>
            <Col xs={12} className="text-center">
              <p className="lead">Audire aims to make your event a huge success.</p>
              <p className="lead">Whether it is a small gig or a large ceremony, we provide professional services at competitive rates.</p>
            </Col>
          </Row>
        </Container>
      </Row>

      <ImageRow />

      <Row className="bg-theme py-5">
        <Container>
          <Row>
            <Col xs={12} md={6}>
              <h1 className="pb-2 border-bottom">Event Support</h1>
            </Col>
            <Col xs={12} md={6}>
              <h2>Sound Equipment Rental</h2>
              <h2>Sound Technicians</h2>
              <h2>Photography and Videography</h2>
              <p className="mt-5">Using our vast inventory of Audio Equipment, we will provide quality sound support for your event. Services include the transport of equipment, set-up of equipment, and a sound technician to ensure smooth running of the program flow. Photography and Videography services are also available.</p>
            </Col>
          </Row>
        </Container>
      </Row>

      <ContactForm />

    </Container>
  </Layout >
);

export default IndexPage;
