import React from 'react';
import { graphql, StaticQuery } from 'gatsby';
import {
  Col,
  Row,
  Card,
  CardColumns,
} from 'reactstrap';
import Img from "gatsby-image";

const ImageRow = () => (
  <StaticQuery query={graphql`
      query {
        ProjectImgs: allFile(
          sort: { order: ASC, fields: [absolutePath] }
          filter: { relativePath: { regex: "/projects/.*.jpg/" } }
        ) {
          edges {
            node {
              relativePath
              name
              childImageSharp {
                fluid(quality: 90, maxWidth: 4160) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    `}
    render={data => {
      const { edges: projectImgData } = data.ProjectImgs;
      return (
        <Row className="py-5">
          <Col xs={12} className="text-theme mb-3 border-bottom pb-1">
            <h1>Grooving to the beat</h1>
          </Col>
          <Col xs={12}>
            <CardColumns>
              {
                projectImgData.map(({ node }, index) => (
                  <Card key={index} className="shadow-sm rounded-0">
                    <Img title={node.name} alt={node.name} fluid={node.childImageSharp.fluid} className="card-img-top w-100 rounded-0" />
                  </Card>
                ))
              }
            </CardColumns>
          </Col>
        </Row>
      )
    }
    }
  />
);

export default ImageRow;
